import React from 'react'
import { Link } from 'gatsby'
import styles from './new-client.module.sass'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Contact from '../components/contact'

const SignupPage = () => (
   <Layout>
      <SEO title="Join Our Current Trends &amp; News" />
      <div className={['page', styles.page__new_client].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Join Our Current Trends &amp; News
            </h1>
            <p className={['page--subheader', styles.page__subheader].join('')}>
               A weekly financial recap curated by SPC's team of wealth
               management and tax-integrated advisors.
               <Link to="/legal/disclaimers#tax">*</Link>
               <br />
               We monitor and explore the intricacies of the financial world and
               share insights into market developments.
            </p>
         </div>
         <div className={styles.content}>
            <Contact page="signup" />
         </div>
      </div>
   </Layout>
)

export default SignupPage
